.bursts {
    width: 100%;
    .bursts-container {
        @extend .flex;

        a.burst2 {
            width: 50%;
            img {
                width: 100%;
            }
            .border {
                top: 235px !important;
                right: 10% !important;
            }
        }

        .burst {
            //flex: 1;
            position: relative;
            .text-container, .overlay {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 65%;
                transition: all 0.3s ease-in; // IN effect
            }
            .overlay {
                background: rgba(0, 0, 0, 0.7);
            }
            .text-container {
                padding: 1.25rem;
                .border {
                    border: none;
                    width: 100%;
                    display: flex;
                    align-items: flex-end;
                    position: absolute;
                    right: 5%;
                    top: 165px;
                    @extend .flex;
                    @extend .flex-column;
                    @extend .justify-center;
                    @extend .items-center;
                    transition: all 0.3s ease-out; // OUT effect
                }
            }
            .overlay {
                /*opacity: 0;*/
                transition: all 0.5s ease-out; // OUT effect
            }
            h3,h4 {
                color: $color-white;
                transition: all 0.2s ease-out; // OUT effect
            }
            h3 {
                @extend .font-2-normal;
                font-size: $font-size-26;
                font-weight: bold;
                text-transform: none;
                line-height: 1;
                font-family: $font-family-1;
            }
            h4 {
                @extend .font-1-normal;
                font-size: 2.5vw;
            }
            p {
                color: #fff;
                font-weight: 900;
                font-size: $font-size-26;
                margin: 0;
                text-transform: uppercase;
            }
        }
        a:hover .burst {
            .overlay {
                width: 100%;
                transition: all 0.5s ease; // IN effect
            }
            .border {
                //border-color: $color-secondary;
                transition: all 0.3s ease; // IN effect
            }
        }
    }
}
@media screen and (max-width: $size-slider-breakpoint) {
    .bursts {
        .bursts-container {
            flex-direction: column;
            .burst {
                h3 {
                    font-size: 2rem;
                }
                h4 {
                    font-size: 1rem;
                }
            }
        }
    }
}