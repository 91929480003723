// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#blocTexte-Image {
    padding: 150px 5%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;

    .left-side {
        text-align: right;
        padding: 20px 45px 20px 0;
        width: 52%;
        h3 {
            font-size: $font-size-36;
            font-weight: 900;
            color: #000;
            padding-bottom: 10px;
        }
        p {
            font-weight: normal;
            font-size: $font-size-24;
            line-height: 1.8;
            padding-bottom: 50px;
            text-align: justify;
        }
        a.bouton {
            background: #282828;
            padding: 15px 60px;
            color: #fff;
            font-size: $font-size-26;
            font-weight: bold;
            transition: 0.3s;
            text-transform: uppercase;
            &:hover {
                background: #bf1e2e;
                transition: 0.3s;
            }
        }
    }
    .right-side {
        width: 45%;
    } 
}

#blocParallax {
    background: url("../images/accueil_section02_bg.jpg") no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 175px 5%;
    text-align: center;

    h3 {
        font-size: $font-size-48;
        font-weight: 900;
        color: #fff;
        padding-bottom: 50px;
    }
    .blocServices {
        display: flex;
        justify-content: center;
        p {
            font-weight: bold;
            font-size: $font-size-30;
            line-height: 1;
            color: #fff;
            text-transform: uppercase;
            border-right: 3px solid #fff;
            padding-right: 40px;
            padding-left: 40px;
        }
        p.last {
            border-right: none;
            padding-right: 0;
        }
    }
}

#bloc50-50 {
    display: flex;
    padding: 75px 2%;
    justify-content: space-between;
    .left-side {
        padding-top: 1%;
    }
    .right-side {
        iframe {
            width: 650px;
            height: 365px;
        }
    }
    h3 {
        font-size: $font-size-26;
        font-weight: 800;
        color: #fff;
        padding: 15px 25px;
        background: #bf1e2e;
        text-align: center;
        margin-bottom: 40px;
    }
    .blocImages {
        display: flex;
        flex-wrap: wrap;
        .images {
            width: 20%;
            padding: 5px;
        }
    }  
}

/*******************************************************************************
* À PROPOS
*******************************************************************************/

#blocIntro_propos {
    padding: 150px 5% 50px 9%;
    text-align: right;
    h3 {
        font-weight: 900;
        color: #000;
        font-size: $font-size-36;
        padding-bottom: 20px;
    }
    p {
        font-weight: normal;
        color: #000;
        font-size: $font-size-24;
    }
    .blocLogo {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: 50px;
        .logo.first {
            margin-right: 75px;
        }
    }
}

#bloc50-50_propos {
    padding: 50px 0 100px 5%;
    .blocTitre {
        background: #bf1e2e;
        padding: 15px 20px;
        h3 {
            font-weight: 900;
            color: #fff;
            font-size: $font-size-36;
        }
    }
    .texte-image {
        padding: 75px 5% 0 0;
        display: flex;
        align-items: center;
        .left-side {
            width: 47%;
            margin-right: 13%;
            p {
                font-weight: normal;
                color: #000;
                font-size: $font-size-24;
                text-align: justify;
                margin: 0;
            }
        }
        .right-side {
            width: 40%;
        }
    }
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#blocIntro_services {
    padding: 130px 5% 100px 52%;
    text-align: right;
    h3 {
        font-weight: 900;
        color: #000;
        font-size: $font-size-36;
        padding-bottom: 20px;
    }
    p {
        font-weight: normal;
        color: #000;
        font-size: $font-size-24;
        text-align: justify;
        margin: 0;
        padding-bottom: 50px;
    }
    a.btnIntro {
        background: #231f20;
        padding: 15px 60px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 900;
        font-size: $font-size-24;
        transition: 0.3s;
        &:hover {
            background: #bf1e2e;
            transition: 0.3s;
        }
    }
}

#blocParallax_services {
    background: url("../images/services_section02_bg_paralax.jpg") no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 70px 5%;
    text-align: center;

    h3 {
        font-size: $font-size-48;
        font-weight: 900;
        color: #fff;
        padding-bottom: 15px;
    }
    p {
        font-size: $font-size-24;
        font-weight: normal;
        color: #fff;
    }
}

#bloc50-50_services {
    padding: 100px 5% 100px 0;

    .blocTitre {
        background: #bf1e2e;
        padding: 15px 5%;
        h3 {
            font-weight: 800;
            color: #fff;
            font-size: $font-size-26;
        }
    }
    .texte-image {
        display: flex;
        justify-content: space-between;
        .left-side {
            padding: 50px 5% 0 5%;
            width: 58%;
            p {
                font-weight: normal;
                color: #000;
                font-size: $font-size-24;
                margin: 0;
                text-align: justify;
            }
        }
        .right-side {

        }
    }
}
/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

.section-projets {
    padding-top: 200px;
    .bande {
        background: url("../images/bande-rouge.jpg") no-repeat;
        background-position: right;
        height: 55px;
    }
   .section-projets--item {
        display: flex;
        .section-projets--image {
            width: 50%;
        }
        .section-projets--text {
            width: 50%;
            background: #000;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: right;
            h3 {
                font-weight: 900;
                color: #bf1e2e;
                font-size: $font-size-36;
                width: 70%;
                margin: auto;
            }
            p {
                font-weight: normal;
                color: #fff;
                font-size: $font-size-24;
                margin: 0;
                padding: 10px 0;
                width: 70%;
                margin: auto;
            }
            a.btnRealisation {
                font-weight: 800;
                color: #000;
                font-size: $font-size-26;
                text-transform: uppercase;
                background: #fff;
                transition: 0.2s;
                width: 30%;
                text-align: center;
                padding: 15px 10px;
                position: relative;
                left: 55%;
                &:hover {
                    background: #bf1e2e;
                    transition: 0.2s;
                }
            }
        }
    } 
}

.mfp-iframe-holder {
    padding-top: 0px;
    padding-bottom: 0px;
    padding: 0;

    .mfp-content {
        max-width: 1920px;
        .mfp-close {
            top: 0;
            right: 0;
            color: #000;
        }
    }
}

.container {
    .row {
        .col-xs-12 {
            h3.page-subtitle {
                padding-top: 10%;
                font-weight: 900;
                color: #bf1e2e;
                font-size: $font-size-36;
            }
            p {
                font-weight: normal;
                color: #000;
                font-size: $font-size-24;
                margin: 0;
                width: 75%;
                padding: 15px 0 50px 0;
            }
        }
        .amazingslider-bullet-1 {
            background-color: #bf1e2e !important;
        }   
        .amazingslider-nav-featuredarrow-1 {
            display: none !important;
        } 
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#bloc50-50_coordonnees {
    display: flex;
    align-items: center;
    padding: 175px 5% 100px 5%;
    .left-side {
        width: 60%;
        .logo-titre {
            display: flex;
            .logo {
                padding-right: 15px;
            }
        }
        .telephone {
            padding: 65px 0;
            h3 {
                padding-bottom: 10px;
            }
        }
        .facebook {
            .logo-titre {
                align-items: center;
            }
        }
        h3 {
            font-weight: 900;
            color: #000;
            font-size: $font-size-36;
        }
        p {
            font-weight: normal;
            color: #000;
            font-size: $font-size-24;
            margin: 0;
            line-height: 1.5;
        }
    }
    .right-side {
        width: 50%;
    }
}

#blocFormulaire {
    padding: 0 0 0 5%;
    position: relative;
    .blocTitre {
        background: #bf1e2e;
        padding: 15px 20px;
        margin-bottom: 35px;
        h3 {
            font-weight: 900;
            color: #fff;
            font-size: $font-size-36;
        }
    }
    .container {
        width: 1450px;
    }
}



/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1850px) {

.section-projets .bande {
    position: relative;
    left: 10%;
    width: 90%;
}
}

@media screen and (max-width: 1730px) {
   
#blocTexte-Image .right-side {
    width: 50%;
}
#blocTexte-Image .left-side p br {
    display: none;
}
.slideshow-wrap .caption .text {
    bottom: 25%;
}
.bursts .bursts-container .burst .text-container .border {
    top: 150px;
}
.bursts .bursts-container .burst .text-container, .bursts .bursts-container .burst .overlay {
    width: 75%;
}
form #right {
    right: 5%;
}
#bloc50-50 .left-side {
    width: 50%;
}
}

@media screen and (max-width: 1600px) {

#bloc50-50 h3 {
    font-size: 1.1rem;
}
#bloc50-50_coordonnees .left-side h3 {
    font-size: 1.6rem;
}
#bloc50-50_coordonnees .left-side p {
    font-size: 1.1rem;
}
form #right {
    right: 2%;
}
}

@media screen and (max-width: 1570px) {
   
.bursts .bursts-container .burst .text-container, .bursts .bursts-container .burst .overlay {
    width: 80%;
}
.bursts .bursts-container .burst .text-container .border {
    top: 135px;
}
#blocTexte-Image .left-side {
    width: 57%;
}
#blocTexte-Image .left-side h3 {
    font-size: 1.4rem;
}
#blocTexte-Image .left-side p {
    font-size: 1rem;
}
#blocTexte-Image .left-side a.bouton {
    padding: 15px 40px;
    font-size: 1.1rem;
}
#blocParallax {
    padding: 175px 3%;
}
#blocParallax h3 {
    font-size: 2.2rem;
}
#blocParallax .blocServices p {
    font-size: 1.3rem;
}
form #right {
    right: 1%;
}
#blocParallax_services p br {
    display: none;
}
#blocIntro_services {
    padding: 130px 5% 100px 50%;
}
.section-projets .section-projets--item .section-projets--text p {
    padding: 5px 0;
}
#bloc50-50 .blocImages .images {
    width: 30%;
    margin: auto;
    padding: 0 15px 15px 15px;
}
}

@media screen and (max-width: 1545px) {

.section-projets .section-projets--item .section-projets--text p {
    font-size: 1.1rem;
}
.section-projets {
    padding-top: 175px;
}
.mfp-wrap.mfp-close-btn-in.mfp-auto-cursor.mfp-ready {
    top: 0 !important;
}
}

@media screen and (max-width: 1500px) {

.mfp-iframe-scaler {
    height: 1220px;
}
}

@media screen and (max-width: 1475px) {

#blocFormulaire .container {
    width: 1170px;
}
form #right {
    right: 7%;
}
}

@media screen and (max-width: 1430px) {

.bursts .bursts-container .burst p {
    font-size: 1.2rem;
}
.bursts .bursts-container .burst .text-container .border {
    top: 50px;
}
#blocTexte-Image .left-side {
    width: 50%;
}
#blocTexte-Image .right-side {
    width: 55%;
}
.bursts .bursts-container .burst .text-container .border {
    top: 120px;
}
form #right {
    right: 5%;
}
#blocIntro_services {
    padding: 130px 5% 100px 45%;
}
.section-projets .section-projets--item .section-projets--text p {
    font-size: 1rem;
}
.section-projets .section-projets--item .section-projets--text h3 {
    font-size: 1.6rem;
}
.section-projets .section-projets--item .section-projets--text a.btnRealisation {
    font-size: 1.1rem;
}
#bloc50-50 .right-side iframe {
    width: 560px;
    height: 315px;
}
}

@media screen and (max-width: 1395px) {

#blocParallax .blocServices p {
    font-size: 1.1rem;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a div, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > .menu-title div {
    font-size: 1rem;
}
nav.module-menu .menu-navbar .wrapper.right .facebook-tel > ul li.phone a div.phonenumber, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li.phone a div.phonenumber {
    font-size: 1.2rem;
}
#blocTexte-Image .left-side {
    width: 45%;
}
#bloc50-50_coordonnees {
    padding: 175px 3% 100px 3%;
}
#bloc50-50_coordonnees .left-side h3 {
    font-size: 1.4rem;
}
#bloc50-50_coordonnees .left-side p {
    font-size: 1rem;
}
}

@media screen and (max-width: 1350px) {
   
#blocTexte-Image {
    padding: 150px 5% 100px 5%;
}
#blocTexte-Image .left-side p {
    font-size: 0.9rem;
}
form #right {
    right: 3%;
}
#bloc50-50_propos .texte-image {
    flex-direction: column-reverse;
}
#bloc50-50_propos .texte-image .left-side {
    width: 80%;
    margin-right: 0;
}
#bloc50-50_propos .texte-image .right-side {
    width: 75%;
    padding-bottom: 50px;
}
#bloc50-50_propos .texte-image .right-side img {
    margin: auto;
}
.section-projets {
    padding-top: 160px;
}
}

@media screen and (max-width: $size-md-max) {

.bursts .bursts-container .burst .text-container .border {
    top: 110px;
}
nav.module-menu .menu-navbar {
    height: 100px;
}  
nav.module-menu .menu-navbar.nav-invisible {
    top: -100px;
} 
#blocTexte-Image {
    padding: 125px 5% 100px 5%;
}
form #right {
    right: 1%;
}
form label.image {
    margin-left: 42px;
}
#blocIntro_propos {
    padding: 125px 5% 50px 9%;
}
#blocParallax_services {
    padding: 100px 5%;
}
.mfp-iframe-holder .mfp-content .mfp-close {
    right: 45px;
}
.mfp-container {
    height: 0 !important;
}
.mfp-iframe-scaler iframe {
    height: 1035px;
}
.mfp-iframe-scaler {
    height: 0;
}
}

@media screen and (max-width: 1200px) {

#bloc50-50 h3 {
    font-size: 1rem;
}
form #right {
    right: 8%;
}
#blocFormulaire .container {
    width: 900px;
}
form .form-group {
    padding-right: 15px;
}
#blocIntro_services {
    padding: 115px 5% 100px 40%;
}
.section-projets .section-projets--item .section-projets--text p {
    font-size: 0.9rem;
}
.section-projets .section-projets--item .section-projets--text h3 {
    font-size: 1.5rem;
}
.section-projets .section-projets--item .section-projets--text a.btnRealisation {
    font-size: 1rem;
}
#bloc50-50 {
    flex-direction: column;
}
#bloc50-50 .left-side {
    width: 75%;
    margin: auto;
    padding-bottom: 35px;
}
#bloc50-50 .right-side {
    margin: auto;
}
#bloc50-50 .blocImages .images {
    width: 20%;
    padding: 5px;
}
}

@media screen and (max-width: 1150px) {

#blocTexte-Image {
    flex-direction: column;
    padding: 100px 5%;
}
#blocTexte-Image .left-side {
    width: 100%;
    text-align: left;
}
#blocTexte-Image .right-side {
    width: 60%;
    margin: auto;
}
#blocTexte-Image .left-side {
    padding-bottom: 60px;
}
#blocParallax .blocServices p {
    font-size: 1rem;
}
.bursts .bursts-container .burst .text-container .border {
    top: 90px;
}
#bloc50-50_coordonnees {
    padding: 125px 3% 100px 3%;
}
.module-header .blocTitre {
    top: 50%;
    left: 80%;
}
#bloc50-50_services .texte-image {
    flex-direction: column-reverse;
}
#bloc50-50_services .texte-image .left-side {
    padding: 50px 5% 0 5%;
    width: 100%;
}
}

@media screen and (max-width: 1100px) {

#bloc50-50 h3 {
    font-size: 0.9rem;
}
.bursts .bursts-container .burst .text-container .border {
    top: 80px;
}
#blocParallax {
    padding: 130px 3%;
}
#blocParallax h3 {
    font-size: 2rem;
}
#blocParallax .blocServices p {
    font-size: 0.9rem;
}
#blocIntro_services {
    padding: 115px 5% 100px 30%;
}
#blocParallax_services {
    padding: 80px 5%;
}
.section-projets {
    padding-top: 150px;
}
}

@media screen and (max-width: 1024px) {
   
.bursts .bursts-container .burst h3, .bursts .bursts-container .burst p {
    font-size: 1rem;
}
#blocParallax h3 {
    font-size: 1.8rem;
}
.slideshow-wrap .caption {
    width: 550px;
    padding: 0 50px;
}
#bloc50-50_coordonnees .left-side h3 {
    font-size: 1.3rem;
}
#bloc50-50_coordonnees {
    padding: 175px 2% 100px 2%;
}
#blocIntro_propos {
    padding: 100px 5% 50px 9%;
}
.module-header--caption {
    width: 580px;
    height: 130%;
}
.module-header .blocTitre {
    top: 47%;
    left: 75%;
}
.section-projets .section-projets--item {
    flex-direction: column-reverse;
    padding-bottom: 75px;
}
.section-projets .section-projets--item .section-projets--image, .section-projets .section-projets--item .section-projets--text {
    width: 70%;
    margin: auto;
}
.section-projets .section-projets--item .section-projets--text  {
    padding: 45px 0;
}
.section-projets .section-projets--item .section-projets--text p {
    font-size: 1rem;
    width: 80%;
}
.section-projets .section-projets--item .section-projets--text h3 {
    font-size: 1.6rem;
    width: 80%;
}
.section-projets .section-projets--item .section-projets--text a.btnRealisation {
    font-size: 1.2rem;
    left: 60%;
}
.section-projets .bande {
    display: none;
}
.mfp-iframe-holder .mfp-content .mfp-close {
    right: 10px;
}
}

@media screen and (max-width: $size-sm-max) {
    
.bursts .bursts-container .burst .text-container, .bursts .bursts-container .burst .overlay {
    width: 60%;
}
.slideshow-wrap .caption {
    width: 490px;
}
#blocTexte-Image .right-side {
    width: 100%;
    margin: auto;
}
#blocTexte-Image .left-side h3 {
    font-size: 1.6rem;
}
#blocTexte-Image .left-side p {
    font-size: 1.1rem;
}
#blocTexte-Image .left-side a.bouton {
    padding: 15px 40px;
    font-size: 1.1rem;
}
#blocTexte-Image {
    padding: 70px 5% 90px 5%;
}
#blocParallax .blocServices {
    flex-direction: column;
}
#blocParallax h3 {
    font-size: 2.4rem;
}
#blocParallax .blocServices p {
    font-size: 1.5rem;
    border-right: none;
    padding-right: 0;
    padding-left: 0;
    line-height: 1.5;
}
#blocParallax {
    padding: 85px 3%;
    background-position: center;
}
.bursts .bursts-container {
    flex-direction: column;
}
.bursts .bursts-container .burst .img-responsive {
    width: 100%;
}
.bursts .bursts-container .burst .text-container .border {
    top: 225px;
}
.bursts .bursts-container .burst h3, .bursts .bursts-container .burst p {
    font-size: 1.5rem;
}
#bloc50-50_coordonnees {
    padding: 100px 5%;
    flex-direction: column;
}
#bloc50-50_coordonnees .left-side {
    width: 100%;
}
#bloc50-50_coordonnees .right-side {
    width: 100%;
    padding-top: 50px;
}
#bloc50-50_coordonnees .left-side h3 {
    font-size: 1.6rem;
}
#bloc50-50_coordonnees .left-side p {
    font-size: 1.2rem;
}
.module-header .blocTitre {
    top: 37%;
    left: 83%;
}
.module-header--caption {
    height: 125%;
}
#blocFormulaire .container {
    width: 750px;
}
form .form-group {
    padding-right: 70px;
}
form #right {
    right: -4%;
}
form label.image {
    margin-left: 71px;
}
#bloc50-50_services {
    padding: 100px 5% 50px 0;
}
#blocParallax_services p {
    margin: 0;
}
#blocParallax_services {
    padding: 70px 5%;
}
#bloc50-50 .left-side {
    width: 95%;
}
#bloc50-50 .blocImages .images {
    width: 35%;
    padding: 0 15px 15px 15px;
    margin: auto;
}
}

@media screen and (max-width: $size-xs-max) {
   
.slideshow-wrap .caption {
    width: 290px;
    padding: 0 60px;
}
.bursts .bursts-container .burst .text-container .border {
    top: 100px;
}
.bursts .bursts-container .burst .text-container, .bursts .bursts-container .burst .overlay {
    width: 80%;
}
.module-header--caption {
    height: 112%;
    width: 485px;
}
form #right {
    position: initial;
}
form label.image {
    margin-left: 0;
}
#blocFormulaire .container {
    width: 415px;
}
.module-header .blocTitre {
    top: 62%;
    left: 90%;
}
#blocIntro_propos {
    padding: 100px 5% 20px 5%;
    text-align: left;
}
#blocIntro_propos .blocLogo {
    flex-direction: column-reverse;
}
#blocIntro_propos .blocLogo .logo.first {
    margin-right: 0;
    padding-top: 50px;
}
#bloc50-50_propos .texte-image .right-side {
    width: 100%;
    padding-bottom: 35px;
}
#bloc50-50_propos .texte-image .left-side {
    width: 100%;
}
#bloc50-50_propos {
    padding: 50px 0 60px 5%;
}
#blocIntro_services {
    padding: 100px 5%;
    text-align: left;
}
.section-projets .section-projets--item .section-projets--image, .section-projets .section-projets--item .section-projets--text {
    width: 100%;
}
.section-projets .section-projets--item .section-projets--text h3 {
    font-size: 1.8rem;
    width: 85%;
}
.section-projets .section-projets--item .section-projets--text p {
    font-size: 1.2rem;
    width: 85%;
}
.section-projets .section-projets--item .section-projets--text a.btnRealisation {
    font-size: 1.2rem;
    left: 62%;
}
.mfp-iframe-holder .mfp-content .mfp-close {
    right: 2px;
}
.container .row .col-xs-12 p {
    width: 100%;
}
.mfp-iframe-scaler iframe {
    height: 740px;
}
.section-projets .bande {
    display: block;
    left: 13%;
    width: 87%;
}
.container .row .col-xs-12 h3.page-subtitle {
    padding-top: 30%;
}
#bloc50-50 .blocImages .images {
    width: 50%;
}
#bloc50-50 .right-side iframe {
    width: 395px;
    height: 225px;
}
}

@media screen and (max-width: 360px) {

#blocFormulaire .blocTitre h3 {
    font-size: 1.5rem;
}
#blocFormulaire .container {
    width: 350px;
}
.btn {
    width: 80%;
}
.section-projets .section-projets--item .section-projets--text h3 {
    font-size: 1.7rem;
    width: 90%;
}
.section-projets .section-projets--item .section-projets--text p {
    font-size: 1.1rem;
    width: 90%;
}
.section-projets .section-projets--item .section-projets--text a.btnRealisation {
    font-size: 1.2rem;
    left: 45%;
    width: 50%;
}
.mfp-iframe-scaler iframe {
    height: 650px;
}
.container .row .col-xs-12 h3.page-subtitle {
    font-size: 1.7rem;
}
#bloc50-50 .right-side iframe {
    width: 340px;
    height: 190px;
}
}