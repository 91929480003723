.slideshow-wrap {
    position: relative;
    background-color: black;
    //#layerslider { display: block; }
    //#layerslider-mobile { display: none; }
    //@media screen and (max-width: $size-slider-breakpoint) {
    //    #layerslider { display: none; }
    //    #layerslider-mobile { display: block; }
    //}

    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }
    .subtitle {
        color: $color-white;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-size: $font-size-24;
        text-align: center;
        padding-bottom:0.5rem;
    }
    .title {
        @extend .font-2-normal;
        text-transform: uppercase;
        color: $color-primary;
        font-size: $font-size-50;
        text-align: center;
    }
    .ls-l a {
        width: auto;
    }

    .caption {
        position: absolute;
        top: 0;
        left: 0%;
        transform: translateX(-50%);
        z-index: $z-index-slide-caption;
        width: 650px;
        background: rgba(0,0,0,0.5);
        padding: 0 100px;
        height: 110%;
        h3 {
            font-size: $font-size-48;
            color: #fff;
            font-weight: bold;
            text-transform: initial;
            text-shadow: 0 0 10px black;
            transition: 2s;
            transform: translateX(-50%);
        }
        span {
            color: #bf1e2e;
        }
        .text {
            position: absolute;
            bottom: 33%;
        }
        &.show {
            transform: translateX(0);
            transition: 1s;
            h3 {
                transform: translateX(0);
                transition: 2s;
            }
        }
    }
    
    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: $font-size-10;
            border-width: 1px;
            padding: 0.25rem;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        //.title {
        //    font-size: 26px;
        //}
        //.subtitle {
        //    font-size: 16px;
        //}
    }
}

