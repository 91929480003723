.module-header {
    position: relative;

    &--image {}

    &--image-mobile {}

    .blocTitre {
        width: 100%;
        position: absolute;
        top: 55%;
        left: 75%;
    }

    &--caption {
        position: absolute;
        top: 0;
        left: 17%;
        transform: translateX(-50%);
        z-index: $z-index-slide-caption;
        width: 705px;
        background: rgba(0,0,0,0.5);
        padding: 0 100px;
        height: 120%;

        &.show {
            transform: translateX(0);
            transition: 1s;
            h3 {
                transform: translateX(0);
                transition: 2s;
            }
        }
        
        &--title {
            font-size: $font-size-48;
            color: #fff;
            font-weight: 900;
            text-transform: initial;
            /*text-shadow: 0 0 10px black;*/
            transition: 2s;
            transform: translateX(-50%);
        }
        &--subtitle {
            font-size: $font-size-48;
            color: #bf1e2e;
            font-weight: 900;
            text-transform: uppercase;
            /*text-shadow: 0 0 10px black;*/
            transition: 2s;
            transform: translateX(-50%);
        }

    }
}
/*@media screen and (max-width: $size-md-max) {
    .module-header {
        &--caption {
            &--title {
                font-size: 6vw;
            }
            &--subtitle {
                font-size: 4vw;
            }
        }
    }
}
@media screen and (max-width: $size-sm-max) {
    .module-header {
        &--caption {
            &--title {
                font-size: 7vw;
            }
            &--subtitle {
                font-size: 5vw;
            }
        }
    }
}
@media screen and (max-width: $size-xs-max) {
    .module-header {
        &--caption {
            &--title {
                font-size: 8vw;
            }
            &--subtitle {
                font-size: 6vw;
            }
        }
    }
}*/
