.btn {
    background-color: $color-black;
    color: $color-white;
    padding: 10px;
    font-size: $font-size-26;
    text-transform: uppercase;
    font-weight: 800;
    width: 100%;
    transition: all 0.2s ease-in;
    span.btn-em {
        font-size: $font-size-20;
        text-transform: uppercase;
        font-weight: 600;
    }
    &:hover {
        transition: all 0.2s ease-in;
        background-color: #bf1e2e;
        color: #fff;
    }
}
